import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
// import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
// import { useParams } from 'react-router-dom'
// import Teams from '../../api/team';
import Footer from '../../components/footer'
import Logo from '../../images/logo.png'
// import TeamSection from '../../components/TeamSection'
import TeamSection2 from '../../components/TeamSection2'



const TeamPage = (props) => {

    return (
        <Fragment>
            <Navbar Logo={Logo}/>
            <TeamSection2/>
            {/* <div className="attorney-pg-area section-padding">
                <div className="container">
                    <div className="attorney-info-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="attorney-info-img">
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="attorney-info-text">
                                    <ul>
                                        <li>Position: <span> </span></li>
                                        <li>Responsibility:<span></span></li>
                                        <li>Location:<span>12 Years</span></li>
                                        <li>Contact:<span>12 Years</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="exprience-area">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="exprience-wrap">
                                    <h2>Personal Experience</h2>
                                    <p>The purpose of lorem ipsum is to create a natural looking block oftext (sentence, paragraph, page, etc.) that doesn't distract from thelayout. A practice not without controversy, laying out pages withmeaningless filler text can be very useful when the focus is meantto be on design, not content.There are many variations of passages of Lorem Ipsum available.</p>
                                    <p>But the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text generators on the Internet tend to repeat.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default TeamPage;
