import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero2 from '../../components/hero2'
import Service from '../../components/Service'
import AboutS2 from '../../components/AboutS2'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import FunFact from "../../components/FunFact";



const HomePage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo} hclass={'wpo-header-style-2'}/>
            <Hero2/>
            <Service Fclass={'wpo-features-section-s2'}/>
            <FunFact/>
            <AboutS2/>
            {/*<Testimonial/>*/}
            {/*<CauseSection/>*/}
            {/*<ProjectSection/>*/}
            {/*<EventSection/>*/}
            {/*<BlogSection/>*/}
            {/*<PartnerSection/>*/}
            <Scrollbar/>
            <Footer/>
        </Fragment>
    )
};
export default HomePage;